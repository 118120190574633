<script setup lang="ts">
import { computed } from "vue";

const emit = defineEmits(["update:checked"]);

const props = defineProps<{
  checked: boolean | string[];
  value?: any;
}>();

const proxyChecked = computed({
  get() {
    return props.checked;
  },

  set(val) {
    emit("update:checked", val);
  },
});
</script>

<template>
  <input
    v-model="proxyChecked"
    type="checkbox"
    :value="value"
    class="h-4 w-4 rounded border-gray-300 text-gray-900 focus:ring-gray-900"
  />
</template>
